/* eslint-disable no-unused-vars */
import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Amplify, Auth, API } from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';

Amplify.configure(awsconfig);

function getData() {
  const apiName = 'apiab7ab292';
  const path = '/getorders';
  const myInit = {
    headers: {}, // OPTIONAL
    response: true
  };

  return API.post(apiName, path, myInit);
}


/*
async function runData() {
  try {
    const response = await getData();
    const data = JSON.parse(response.data.body)
    console.log(data)
  } catch (err) {
    console.log(err)
  }
}
*/




class EmailTable extends React.Component {
  render() {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Success?</th>
            <th>Date</th>
            <th>From</th>
            <th>Subject</th>
            <th>Processing comment</th>
          </tr>
        </thead>
        <tbody id="email-table-body">
          {this.props.emails.map((item) =>
            <tr>
              <td>
                {item?.success ? '✔️' : '❌'}
              </td>
              <td>
                {item.date}
              </td>
              <td>
                {item.from.text}
              </td>
              <td>
                {item.subject}
              </td>
              <td>
                {item.message}
              </td>
            </tr>
          )}
          {this.props.emails.length ? <></> : <tr><td colSpan={5}>
            No emails - try clicking the button! Or if you already have, perhaps none arrived since you last clicked it? Go and do more marketing!
          </td></tr>}
        </tbody>
      </Table>
    )
  }
}

class OrdersTable extends React.Component {
  render() {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Order</th>
            <th>Name</th>
            <th>Email</th>
            <th>SKU</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody id="email-table-body">
          {this.props.orders.map((item) =>
            <tr>
              <td>
                {item?.[0]}
              </td>
              <td>
                {item?.[1]}
              </td>
              <td>
                {item?.[8]}
              </td>
              <td>
                {item?.[2]}
              </td>
              <td>
                {item?.[3]}
              </td>
            </tr>
          )}
          {this.props.orders.length ? <></> : <tr><td colSpan={5}>
            No orders - try clicking the button! Or if you already have, perhaps none arrived since you last clicked it? Go and do more marketing!
          </td></tr>}
        </tbody>
      </Table>
    )
  }
}

class MainWrapper extends React.Component {
  constructor() {
    super();
    this.state = {
      emails: [],
      orders: [],
      loading: false,
      excel: '#!'
    }
    this.handleClick = this.handleClick.bind(this);
  }
  async handleClick() {
    this.setState({
      loading: true
    })
    try {
      const response = await getData();
      console.log(response)
      // check for response code: 200 is no error
      if (response.data.statusCode === 200) {
        const data = JSON.parse(response.data.body)
        console.log(data)
        // check that there are orders - first row is header row so need to check for >1 not >0
        if (data.allOrders.length > 1) {
          const excelUInt = new Uint8Array(data.excelOutput.data)
          const excelBlob = new Blob([excelUInt])
          this.setState({
            emails: data.emails,
            orders: data.allOrders.slice(1),
            excel: URL.createObjectURL(excelBlob)
          })
          alert('Orders successfully processed and email sent! The details of the individual emails processed are shown below for your information but no action is needed.')
        } else {
          // otherwise, no error (so there must be some emails) but no orders
          this.setState({
            emails: data.emails,
            orders: [],
            excel: '#!'
          })
          alert('No orders found')
        }
      } else {
        this.setState({
          emails: [],
          orders: [],
          excel: '#!'
        })
        alert(response.data.body)
      }
    } catch (err) {
      console.log(err)
    } finally {
      this.setState({
        loading: false
      })
    }
  }
  render() {
    return (
      <>
        <Row className='my-4'>
          <Col>
            <Button variant="primary" size="lg" onClick={this.handleClick} disabled={this.state.loading}>
              {this.state.loading ?
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className='me-2'
                  id='loading-spinner'
                /> : <></>}
              Process orders
            </Button>
            <span style={{ color: 'blue' }}>&lt;- Click this button once a day</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className='mb-3'>
              <Card.Body>
                <Card.Title>Emails processed</Card.Title>
                <Card.Subtitle className="mb-3">
                  Emails listed below with a success tick have now been archived and will not be included in future runs.<br />
                  Review any emails with an 'X' in the success column. If they look like legitimate orders contact the developer to see why they are not being processed, but if they look like spam then you can ignore them.
                </Card.Subtitle>
                <EmailTable emails={this.state.emails} />
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Orders processed</Card.Title>
                <Card.Subtitle className="mb-3">
                  These orders have been sent out as an email. They are shown below for information only.&nbsp;
                  <a href={this.state.excel} download='Orders.xlsx'>Download orders anyway</a>
                </Card.Subtitle>
                <OrdersTable orders={this.state.orders} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}

function App({ signOut, user }) {
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h1>Hello luggage magnate!</h1>
            <Button variant="secondary" size="sm" onClick={signOut}>Sign out</Button>
          </Col>
        </Row>
        <MainWrapper />
      </Container>
    </>
  );
}

export default withAuthenticator(App);
export { Auth, API }
